import request from '@/utils/request'

// 商品列表
export function goods_index(data) {
  return request({
    url: '/merchant/goods/index',
    method: 'get',
    params: data
  })
}

// 创建商品
export function goods_add(data) {
  return request({
    url: '/merchant/goods/add',
    method: 'post',
    data: data
  })
}
// 编辑商品
export function goods_edit(data) {
  return request({
    url: '/merchant/goods/edit',
    method: 'post',
    data: data
  })
}
// 删除商品
export function goods_del(data) {
  return request({
    url: '/merchant/goods/del',
    method: 'post',
    data: data
  })
}
// 商品详情
export function goods_detail(data) {
  return request({
    url: '/merchant/goods/detail',
    method: 'get',
    params: data
  })
}
// 缺货商品列表
export function goods_outOfStock(data) {
  return request({
    url: '/merchant/goods/outOfStock',
    method: 'get',
    params: data
  })
}

// 商品上下架
export function goods_changeShelves(data) {
  return request({
    url: '/merchant/goods/changeShelves',
    method: 'post',
    data: data
  })
}

// 核心产品
export function goods_changeImportant(data) {
  return request({
    url: '/merchant/goods/changeImportant',
    method: 'post',
    data: data
  })
}
// 修改商品排序
export function goods_changeSort(data) {
  return request({
    url: '/merchant/goods/changeSort',
    method: 'post',
    data: data
  })
}
// 缺货商品补库存
export function goods_changeStock(data) {
  return request({
    url: '/merchant/goods/changeStock',
    method: 'post',
    data: data
  })
}

// 添加品牌
export function brand_add(data) {
  return request({
    url: '/merchant/brand/add',
    method: 'post',
    data: data
  })
}
// 品牌列表
export function brand_index(data) {
  return request({
    url: '/merchant/brand/index',
    method: 'get',
    params: data
  })
}
// 编辑品牌
export function brand_edit(data) {
  return request({
    url: '/merchant/brand/edit',
    method: 'post',
    data: data
  })
}
// 删除品牌
export function brand_del(data) {
  return request({
    url: '/merchant/brand/del',
    method: 'post',
    data: data
  })
}
// 更新品牌状态
export function brand_status(data) {
  return request({
    url: '/merchant/brand/status',
    method: 'post',
    data: data
  })
}

//禁用用户列表
export function forbidden_user_index(data) {
  return request({
    url: '/merchant/userGoodsForbidden/index',
    method: 'get',
    params: data
  })
}

//添加禁用用户
export function forbidden_user_add(data) {
  return request({
    url: '/merchant/userGoodsForbidden/add',
    method: 'post',
    data: data
  })
}

//删除林用用户
export function forbidden_user_del(data) {
  return request({
    url: '/merchant/userGoodsForbidden/del',
    method: 'post',
    data: data
  })
}

//白名单用户列表
export function write_list_index(data) {
  return request({
    url: '/merchant/userGoodsWriteList/index',
    method: 'get',
    params: data
  })
}

//添加白名单用户
export function write_list_add(data) {
  return request({
    url: '/merchant/userGoodsWriteList/add',
    method: 'post',
    data: data
  })
}

//删除白名单用户
export function write_list_del(data) {
  return request({
    url: '/merchant/userGoodsWriteList/del',
    method: 'post',
    data: data
  })
}


//禁用城市列表
export function forbidden_city_index(data) {
  return request({
    url: '/merchant/CityGoodsForbidden/index',
    method: 'get',
    params: data
  })
}

//添加禁用城市
export function forbidden_city_add(data) {
  return request({
    url: '/merchant/CityGoodsForbidden/add',
    method: 'post',
    data: data
  })
}

//删除禁用城市
export function forbidden_city_del(data) {
  return request({
    url: '/merchant/CityGoodsForbidden/del',
    method: 'post',
    data: data
  })
}

//白名单用户列表
export function UserGoodsWriteList_index(data) {
  return request({
    url: '/merchant/UserGoodsWriteList/index',
    method: 'get',
    params: data
  })
}

//添加白名单用户
export function UserGoodsWriteList_add(data) {
  return request({
    url: '/merchant/UserGoodsWriteList/add',
    method: 'post',
    data: data
  })
}

//删除白名单用户
export function UserGoodsWriteList_del(data) {
  return request({
    url: '/merchant/UserGoodsWriteList/del',
    method: 'post',
    data: data
  })
}
//修改商品排行榜--签到
export function goods_changeBestSell(data) {
  return request({
    url: '/merchant/goods/changeBestSell',
    method: 'post',
    data: data
  })
}

//积分商品列表
export function pointGoods_index(data) {
  return request({
    url: '/merchant/pointGoods/index',
    method: 'get',
    params: data
  })
}

//添加积分商品
export function pointGoods_add(data) {
  return request({
    url: '/merchant/pointGoods/add',
    method: 'post',
    data: data
  })
}

//删除积分商品
export function pointGoods_del(data) {
  return request({
    url: '/merchant/pointGoods/del',
    method: 'post',
    data: data
  })
}
//编辑积分商品
export function pointGoods_edit(data) {
  return request({
    url: '/merchant/pointGoods/edit',
    method: 'post',
    data: data
  })
}
//上下架
export function pointGoods_changeShelves(data) {
  return request({
    url: '/merchant/pointGoods/changeShelves',
    method: 'post',
    data: data
  })
}
//修改排序
export function pointGoods_changeSort(data) {
  return request({
    url: '/merchant/pointGoods/changeSort',
    method: 'post',
    data: data
  })
}

// 兑换列表
export function point_Orders_index(data) {
  return request({
    url: '/merchant/pointOrders/index',
    method: 'get',
    params: data
  })
}

// 兑换处理
export function point_Orders_delivery(data) {
  console.log("🚀 ~ point_Orders_delivery ~ data:", data)
  return request({
    url: '/merchant/pointOrders/delivery',
    method: 'post',
    data: data
  })
}

// 检测
export function UserGoodsWriteList_checkForbidden(data) {
  return request({
    url: '/merchant/UserGoodsWriteList/checkForbidden',
    method: 'post',
    data: data
  })
}
